






import { AsyncComponent } from 'vue'
import { Component } from 'vue-property-decorator'

import { logger } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'

import {
  PRODUCT_REVIEWS_KEY, PRODUCT_REVIEWS_MODULE_CONFIG_MAP,
  ProductReviewsConfig,
  ProductReviewsModuleVersion, productReviewsModuleVersionComponentRegistry
} from '../ProductReviews.config'
import { ProductReviewsModule } from '../ProductReviews.contracts'
import { productReviewsContentFactory } from '../ProductReviews.factory'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ProductReviewsModuleUi>({
  name: 'ProductReviewsModuleUi',
  created (): void {
    this.config = this.getComponentConfig(PRODUCT_REVIEWS_KEY, { ...PRODUCT_REVIEWS_MODULE_CONFIG_MAP })
  }
})
export class ProductReviewsModuleUi extends AbstractModuleUi<ProductReviewsModule> {
  protected defaultContent = productReviewsContentFactory()
  protected config!: ProductReviewsConfig

  public get component (): AsyncComponent | undefined {
    const component = productReviewsModuleVersionComponentRegistry[this.productReviewsVersion]

    if (typeof component === 'undefined') {
      logger(
        `ProductReviewsModule.ui.component(): There's no Vue component associated with the [${this.data.version}] ProductReviewsModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  public get productReviewsVersion () {
    if (!this.data.version) {
      return ProductReviewsModuleVersion.Paginate
    }

    return this.data.version
  }
}

export default ProductReviewsModuleUi
